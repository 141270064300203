<template>
  <base-nav
    v-model="showMenu"
    class="navbar-absolute top-navbar"
    type="white"
    :transparent="true"
  >
    <div slot="brand" class="navbar-wrapper">
      <div class="navbar-minimize d-inline"><sidebar-toggle-button /></div>
      <div
        class="navbar-toggle d-inline"
        :class="{ toggled: $sidebar.showSidebar }"
      >
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <a class="navbar-brand" href="#pablo">{{ $t('sidebar.'+ routeName) }}</a>
    </div>

    <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'" >

      <!-- Language select -->   
      <base-dropdown
        tag="li"
        :menu-on-right="!$rtl.isRTL"
        title-tag="a"
        title-classes="nav-link"
        class="nav-item"        
      >
        <template
          slot="title"
        >
          <i class="tim-icons icon-world"></i>
        </template>

        <li>
          <a class="nav-item dropdown-item" @click="$i18n.locale = 'en', setRTL('ltr')"><img class="flags" src="@/assets/img/gb.svg" alt="EN"></a>
        </li>
        <li>
          <a class="nav-item dropdown-item" @click="$i18n.locale = 'ru', setRTL('ltr')"><img class="flags" src="@/assets/img/ru.svg" alt="RU"></a>
        </li>
        <li>
          <a class="nav-item dropdown-item" @click="$i18n.locale = 'de', setRTL('ltr')"><img class="flags" src="@/assets/img/de.svg" alt="DE"></a>
        </li>
        <li>
          <a class="nav-item dropdown-item" @click="$i18n.locale = 'ua', setRTL('ltr')"><img class="flags" src="@/assets/img/ua.svg" alt="UA"></a>
        </li>
        <li>
          <a class="nav-item dropdown-item" @click="$i18n.locale = 'cn', setRTL('ltr')"><img class="flags" src="@/assets/img/cn.svg" alt="CN"></a>
        </li>
        <li>
          <a class="nav-item dropdown-item" @click="$i18n.locale = 'ar', setRTL('rtl')"><img class="flags" src="@/assets/img/sa.svg" alt="AR"></a>
        </li>
        <li>
          <a class="nav-item dropdown-item" @click="$i18n.locale = 'pt', setRTL('ltr')"><img class="flags" src="@/assets/img/pt.svg" alt="PT"></a>
        </li>
        <li>
          <a class="nav-item dropdown-item" @click="$i18n.locale = 'es', setRTL('ltr')"><img class="flags" src="@/assets/img/es.svg" alt="ES"></a>
        </li>
        <li>
          <a class="nav-item dropdown-item" @click="$i18n.locale = 'fr', setRTL('ltr')"><img class="flags" src="@/assets/img/fr.svg" alt="FR"></a>
        </li>
      </base-dropdown>

      <base-dropdown
        tag="li"
        :menu-on-right="!$rtl.isRTL"
        title-tag="a"
        class="nav-item"
        title-classes="nav-link"
      >
        <template
          slot="title"
        >
          <i class="tim-icons icon-single-02"></i>
          <b class="caret d-none d-lg-block d-xl-block"></b>
        </template>

        <li class="nav-link">
            <router-link to="/pages/profile">
              <a class="nav-item dropdown-item"> {{ userName }}</a>
            </router-link>
          <!-- <a href="/pages/profile" class="nav-item dropdown-item">{{ userName }}</a> -->
        </li>
        <!--<li class="nav-link">
          <a href="/pages/profile" class="nav-item dropdown-item">{{ $t('profileAction.profile') }}</a>
        </li>-->
        <div class="dropdown-divider"></div>
        <li class="nav-link">
            <router-link to="#">
              <a class="nav-item dropdown-item" @click.prevent="lock"> {{ $t('sidebar.lock') }}</a>
            </router-link>
          <!-- <a class="nav-item dropdown-item" @click.prevent="logout">{{ $t('profileAction.logOut') }}</a> -->
        </li>
        <li class="nav-link">
            <router-link to="#">
              <a class="nav-item dropdown-item" @click.prevent="logout"> {{ $t('profileAction.logOut') }}</a>
            </router-link>
          <!-- <a class="nav-item dropdown-item" @click.prevent="logout">{{ $t('profileAction.logOut') }}</a> -->
        </li>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions';
import { BaseNav, Modal } from '@/components';
import SidebarToggleButton from './SidebarToggleButton';

export default {
  components: {
    SidebarToggleButton,
    CollapseTransition,
    BaseNav,
    Modal
  },
  computed: {
    userName() {
      return JSON.parse(localStorage.getItem('user')).username;
    },
    routeName() {
      const { name } = this.$route;
      return name; //this.capitalizeFirstLetter(name) UPPERCASE dont work with i18n translate
    },
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: ''
    };
  },
  methods: {
    setRTL (direction) {
      if (direction === "rtl") {
        this.$rtl.enableRTL();
      } else {
        this.$rtl.disableRTL();
      }
      // Update the lang="__" in the html tag when change language
      const getHTMLTag = document.documentElement;
      getHTMLTag.setAttribute("lang", this.$i18n.locale);
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    async logout() {
      await this.$store.dispatch('auth_logout');
      this.$router.push('/login');
    },
    async lock() {
      await this.$store.dispatch('auth_lock');
      this.$router.push('/lock');
    }
  }
};
</script>
<style scoped>
.top-navbar {
  top: 0px;
}
</style>
