import axios from 'axios'
import { responseCheck, errorAnswer } from "@/util/functions.js"

export default {
  actions: {
    //List of all devices for start Device page
    async fetchDev({dispatch}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/device/qlist`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Device by ID information
    async fetchDevById({dispatch}, uid) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/device/qcard/${uid}`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Reset Device heartbeat for move it to "Didn`t connect"
    async resetHeartbeat({dispatch}, uid) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/device/resetheartbeat/${uid}`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Device last played tracks
    async lastPlayed({dispatch}, licKey) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/device/qlastplaytracks/${licKey}/count/6`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //List of free devices for Group adding
    async fetchFreeDev({dispatch}, contentMode) {
      const key = process.env.VUE_APP_PROJECT
      const response = await axios.get(`${key}/api/v2/device/qfree/${contentMode}`)
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Rename Device
    async renameDev({dispatch}, data) {
      const json = JSON.stringify(data);
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.post(`${key}/api/v2/device/edit`, json, {
        headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json'
        }
      }) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response
    },

    //Create Device
    async createDev({dispatch}, {formData, contentType}) {
      const json = JSON.stringify(formData);
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.post(`${key}/api/v2/device/add/${contentType}`, json, {
        headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json'
        }
      }) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },
    
    //Delete Device
    async deleteDev({dispatch}, data) {
      const json = JSON.stringify(data);
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.post(`${key}/api/v2/device/delete`, json, {
        headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json'
        }
      }) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Delete Device, more than one element at once
    async deleteDevMass({dispatch}, data) {
      const json = JSON.stringify(data);
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.post(`${key}/api/v2/device/massdelete`, json, {
        headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json'
        }
      }) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    }
  }
}