import axios from 'axios'
import { responseCheck, errorAnswer } from "@/util/functions.js"

export default {
  actions: {
    // Debug logs
    async DebugLog({dispatch}, uid) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/device/logs/${uid}/debug`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    // Update logs
    async UpdateLog({dispatch}, uid) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/device/logs/${uid}/update`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    // Playlist logs
    async PlaylistLog({dispatch}, uid) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/device/logs/${uid}/playlist`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    // Playtrack logs
    async PlaytrackLog({dispatch}, uid) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/device/logs/${uid}/playtrack`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    // Heartbeat logs
    async HeartbeatLog({dispatch}, uid) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/device/logs/${uid}/heartbeat`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },
    
    // Audit logs - user actions history
    async AuditLog({dispatch}, count) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/audit/${count}`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    }
  }
}