import axios from 'axios'
import { responseCheck, errorAnswer } from "@/util/functions.js"

export default {
  actions: {
    //List of all media for start page for diferent content types
    async fetchMedia({dispatch}, {contentType}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/${contentType}/qlist`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    }, 

    //Media by ID information
    async fetchMediaById({dispatch}, {contentType, uid}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/${contentType}/qcard/${uid}`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Media is in playlists
    async fetchMediaInPl({dispatch}, {contentType, uid}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/${contentType}/get/${uid}/qplaylists`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Media is in groups
    async fetchMediaInGr({dispatch}, {contentType, uid}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/${contentType}/get/${uid}/qgroups`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },
    
    //Upload media in system
    async uploadMedia({dispatch, commit}, {contentType, files}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.post(`${key}/api/v2/${contentType}/upload`, files, {
        headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: function( progressEvent ) {
          commit('setUpload', Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
        }
      }) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Rename media
    async renameMedia({dispatch}, {formData, contentType}) {
      const json = JSON.stringify(formData);
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.post(`${key}/api/v2/${contentType}/edit`, json, {
        headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json'
        }
      }) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Unused media delete
    async unusedMediaDel({dispatch}, {contentType}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.post(`${key}/api/v2/${contentType}/deleteunused`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Delete media
    async deleteMedia({dispatch}, {contentType, forDelete}) {
      const json = JSON.stringify(forDelete);
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.post(`${key}/api/v2/${contentType}/delete`, json, {
        headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json'
        }
      }) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Delete media, more than one element at once
    async deleteMediaMass({dispatch}, {contentType, forDelete}) {
      const json = JSON.stringify(forDelete);
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.post(`${key}/api/v2/${contentType}/massdelete`, json, {
        headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json'
        }
      }) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    }
  }
}