let supportedLocales = {
  en: "English",
  de: "Deutscher",
  ru: "Русский",
  ua: "Украинский",
  cn: "中国人",
  ar: "Arabic",
  es: "Spanish",
  fr: "French",
  pt: "Portuguese"
}

export function getSupportedLocales() {
  let annotatedLocales = []
  

  for (const code of Object.keys(supportedLocales)) {
    annotatedLocales.push({
      code,
      name: supportedLocales[code]
    })
  }

  return annotatedLocales
}

export function supportedLocalesInclude(locale) {
  return Object.keys(supportedLocales).includes(locale)
}