import axios from 'axios'
import { responseCheck, errorAnswer } from "@/util/functions.js"

export default {
  actions: {

    // List of all playlists for start Playlist page (optimized API)
    async fetchPl({dispatch}, contentType) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/playlist/qlist/${contentType}`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    }, 

    //Playlists by ID information
    async fetchPlById({dispatch}, uid) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/playlist/qcard/${uid}/quick`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Playlists content
    async fetchPlContent({dispatch}, uid) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/playlist/qcard/${uid}/withtracks`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },
    
    //Unused playlist delete
    async unusedPlDel({dispatch}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/playlist/deleteunused`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Edit Playlist status
    async editPl({dispatch}, uid) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/action/playlist/${uid}/changestate`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Add melody to playlist
    async addToPl({dispatch}, {track, playlist}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/action/addtrack/${track}/toplaylist/${playlist}`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Dell melody to playlist
    async deleteFromPl({dispatch}, {track, playlist, position}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/action/removetrack/${track}/fromplaylist/${playlist}/by/${position}`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Position of melody in the playlist
    async position({dispatch}, {track, playlist, position, mode}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/action/movetrack/${track}/inplaylist/${playlist}/by/${position}/direction/${mode}`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Create Playlist
    async createPl({dispatch}, {contentType, formData}) {
      const json = JSON.stringify(formData);
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.post(`${key}/api/v2/playlist/add/${contentType}`, json, {
        headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json'
        }
      }) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Rename Playlist
    async renamePl({dispatch}, data) {
      const json = JSON.stringify(data);
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.post(`${key}/api/v2/playlist/edit`, json, {
        headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json'
        }
      }) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Copy Playlist
    async CopyPl({dispatch}, uid) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/playlist/clone/${uid}`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },
    
    //Delete Playlist
    async deletePl({dispatch}, data) {
      const json = JSON.stringify(data);
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.post(`${key}/api/v2/playlist/delete`, json, {
        headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json'
        }
      }) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Delete Playlist, more than one element at once
    async deletePlMass({dispatch}, data) {
      const json = JSON.stringify(data);
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.post(`${key}/api/v2/playlist/massdelete`, json, {
        headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json'
        }
      }) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Upload playlist in M3U
    async uploadPl({dispatch, commit}, formData) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.post(`${key}/api/v2/playlist/upload/m3u/`, formData, {
        headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: function( progressEvent ) {
            commit('setUpload', Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
        }
      }) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },
    
    //Import Smart Playlist
    async importSmartPl({dispatch, commit}, formData) {
      const json = JSON.stringify(formData);
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.post(`${key}/api/v2/playlist/import`, json, {
        headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json'
        },
        onUploadProgress: function( progressEvent ) {
            commit('setUpload', Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
        }
      }) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Smart Playlist SOURCE information for editing
    async sourceSmartPl({dispatch}, uid) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/playlist/source/${uid}`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },
  }
}