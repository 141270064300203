import VueRouter from 'vue-router';
import routes from './routes';

// configure router
const router = new VueRouter({
  mode: 'history', //history mode
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

//Forbiden routing withaut autorization
router.beforeEach((to, from, next) => {
  const currentUser = localStorage.getItem('user');
  const currentRights = currentUser ? JSON.parse(currentUser).role : false;
  const requireAuth = to.matched.some(record => record.meta.auth);
  const requireRights = to.matched.some(record => record.meta.rights);
  const requireNotStreaming = to.matched.some(record => record.meta.notStreaming);
  
  if (requireAuth && !currentUser) {
    return next('/login')
  }
  
  if (requireRights && currentRights !== 'Admin') { 
    return next('/login')
  }

  if (requireNotStreaming && currentRights === 'Streamer') { 
    return next('/login')
  }
    
  next()
});

export default router;
