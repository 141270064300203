import DashboardLayout from '@/pages/Layout/DashboardLayout.vue';
import AuthLayout from 'src/pages/Pages/AuthLayout.vue';
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue';

//Melofone - streaming page
const Streaming = () =>
  import(/* webpackChunkName: "extra" */ '@/pages/_streaming/Streaming.vue');

// Melofone - single objects pages
const VideosOne = () =>
  import(/* webpackChunkName: "extra" */ '@/pages/_melofone/VideosOne.vue');
const VideosAdsOne = () =>
  import(/* webpackChunkName: "extra" */ '@/pages/_melofone/VideosAdsOne.vue');
const AdsOne = () =>
  import(/* webpackChunkName: "extra" */ '@/pages/_melofone/AdsOne.vue');
const DevicesOne = () =>
  import(/* webpackChunkName: "extra" */ '@/pages/_melofone/DevicesOne.vue');
const GroupsOne = () =>
  import(/* webpackChunkName: "extra" */ '@/pages/_melofone/GroupsOne.vue');
const MelodiesOne = () =>
  import(/* webpackChunkName: "extra" */ '@/pages/_melofone/MelodiesOne.vue');
const PlaylistsOne = () =>
  import(/* webpackChunkName: "extra" */ '@/pages/_melofone/PlaylistsOne.vue');
const PublicationsOne = () =>
  import(/* webpackChunkName: "extra" */ '@/pages/_melofone/PublicationsOne.vue');

// Melofone - main objects pages
const Videos = () =>
  import(/* webpackChunkName: "extra" */ '@/pages/_melofone/Videos.vue');
const VideosAds = () =>
  import(/* webpackChunkName: "extra" */ '@/pages/_melofone/VideosAds.vue');
const Ads = () =>
  import(/* webpackChunkName: "extra" */ '@/pages/_melofone/Ads.vue');
const Dashboard = () =>
  import(/* webpackChunkName: "extra" */ '@/pages/_melofone/Dashboard.vue');
const Devices = () =>
  import(/* webpackChunkName: "extra" */ '@/pages/_melofone/Devices.vue');
const Groups = () =>
  import(/* webpackChunkName: "extra" */ '@/pages/_melofone/Groups.vue');
const Melodies = () =>
  import(/* webpackChunkName: "extra" */ '@/pages/_melofone/Melodies.vue');
const Playlists = () =>
  import(/* webpackChunkName: "extra" */ '@/pages/_melofone/Playlists.vue');
const Publications = () =>
  import(/* webpackChunkName: "extra" */ '@/pages/_melofone/Publications.vue');
const Reports = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/_melofone/Charts.vue');

// Basic pages
const Profile = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/UserProfile.vue');
// const Pricing = () =>
//   import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Pricing.vue');
const Login = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Login.vue');
// const Register = () =>
//   import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Register.vue');
const RTL = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/RTLPage.vue');
const Lock = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Lock.vue');

const Wizard = () => import('src/pages/Forms/Wizard.vue');

// Admins pages
const Audit = () =>
import(/* webpackChunkName: "pages" */ '@/pages/_admin/Audit.vue');
const trashMel = () =>
import(/* webpackChunkName: "pages" */ '@/pages/_admin/trashMedia.vue');
const trashDev = () =>
import(/* webpackChunkName: "pages" */ '@/pages/_admin/trashDevices.vue');
const trashGr = () =>
import(/* webpackChunkName: "pages" */ '@/pages/_admin/trashGroups.vue');
const trashPub = () =>
import(/* webpackChunkName: "pages" */ '@/pages/_admin/trashPublications.vue');
const trashPl = () =>
import(/* webpackChunkName: "pages" */ '@/pages/_admin/trashPlaylists.vue');
const Logs = () =>
import(/* webpackChunkName: "pages" */ '@/pages/_admin/Logs.vue');
const Tags = () =>
import(/* webpackChunkName: "pages" */ '@/pages/_admin/Tags.vue');
const confProfile = () =>
import(/* webpackChunkName: "pages" */ '@/pages/_admin/Profile.vue');
const confProfileOne = () =>
import(/* webpackChunkName: "pages" */ '@/pages/_admin/ProfileOne.vue');
const Package = () =>
import(/* webpackChunkName: "pages" */ '@/pages/_admin/Package.vue');
const PackageOne = () =>
import(/* webpackChunkName: "pages" */ '@/pages/_admin/PackageOne.vue');
const PackagePub = () =>
import(/* webpackChunkName: "pages" */ '@/pages/_admin/PackagePub.vue');
const Files = () =>
import(/* webpackChunkName: "pages" */ '@/pages/_admin/Files.vue');
const Channels = () =>
import(/* webpackChunkName: "pages" */ '@/pages/_admin/Channels.vue');
const HeartBeat = () =>
import(/* webpackChunkName: "pages" */ '@/pages/_admin/LogHeartBeat.vue');
const PlayTrack = () =>
import(/* webpackChunkName: "pages" */ '@/pages/_admin/LogPlayTrack.vue');
const Debug = () =>
import(/* webpackChunkName: "pages" */ '@/pages/_admin/LogDebug.vue');
const PlayList = () =>
import(/* webpackChunkName: "pages" */ '@/pages/_admin/LogPlayList.vue');
const UpdatesLog = () =>
import(/* webpackChunkName: "pages" */ '@/pages/_admin/LogUpdates.vue');


// Melofone Security menu  
let melofoneSecurity = {
  path: '/security',
  component: DashboardLayout,
  redirect: '/security/audit',
  name: 'security',
  children: [
    {
      path: 'audit',
      name: 'audit',
      meta: {auth: true, rights: true, notStreaming: true},
      components: { default: Audit }
    },
    {
      path: 'logs',
      name: 'logs',
      meta: {auth: true, rights: true, notStreaming: true},
      components: { default: Logs }
    },
    {
      path: 'tags',
      name: 'tags',
      meta: {auth: true, rights: true, notStreaming: true},
      components: { default: Tags }
    },
    {
      path: 'profile',
      name: 'confProfile',
      meta: {auth: true, rights: true, notStreaming: true},
      components: { default: confProfile }
    },
    {
      path: 'profileOne/:id',
      name: 'confProfileOne',
      meta: {auth: true, rights: true, notStreaming: true},
      components: { default: confProfileOne }
    },
    {
      path: 'heartBeat/:id',
      name: 'heartBeat',
      meta: {auth: true, rights: true, notStreaming: true},
      components: { default: HeartBeat }
    },
    {
      path: 'playTrack/:id',
      name: 'playTrack',
      meta: {auth: true, rights: true, notStreaming: true},
      components: { default: PlayTrack }
    },
    {
      path: 'debug/:id',
      name: 'debug',
      meta: {auth: true, rights: true, notStreaming: true},
      components: { default: Debug }
    },
    {
      path: 'playList/:id',
      name: 'playList',
      meta: {auth: true, rights: true, notStreaming: true},
      components: { default: PlayList }
    },
    {
      path: 'updatesLog/:id',
      name: 'updatesLog',
      meta: {auth: true, rights: true, notStreaming: true},
      components: { default: UpdatesLog }
    }
  ]
};

// Melofone Trash menu  
let melofoneTrash = {
  path: '/trash',
  component: DashboardLayout,
  redirect: '/trash/mediaTrash',
  name: 'trash',
  children: [
    {
      path: 'mediaTrash',
      name: 'mediaTrash',
      meta: {auth: true, rights: true, notStreaming: true},
      components: { default: trashMel }
    },
    {
      path: 'devicesTrash',
      name: 'devicesTrash',
      meta: {auth: true, rights: true, notStreaming: true},
      components: { default: trashDev }
    },
    {
      path: 'groupsTrash',
      name: 'groupsTrash',
      meta: {auth: true, rights: true, notStreaming: true},
      components: { default: trashGr }
    },
    {
      path: 'publicationsTrash',
      name: 'publicationsTrash',
      meta: {auth: true, rights: true, notStreaming: true},
      components: { default: trashPub }
    },
    {
      path: 'playlistsTrash',
      name: 'playlistsTrash',
      meta: {auth: true, rights: true, notStreaming: true},
      components: { default: trashPl }
    }
  ]
};

// Melofone Updates menu  
let melofoneUpdates = {
  path: '/updates',
  component: DashboardLayout,
  redirect: '/updates/package',
  name: 'updates',
  children: [
    {
      path: 'package',
      name: 'package',
      meta: {auth: true, rights: true, notStreaming: true},
      components: { default: Package }
    },
    {
      path: 'packagePub',
      name: 'packagePub',
      meta: {auth: true, rights: true, notStreaming: true},
      components: { default: PackagePub }
    },
    {
      path: 'files',
      name: 'files',
      meta: {auth: true, rights: true, notStreaming: true},
      components: { default: Files }
    },
    {
      path: 'channels',
      name: 'channels',
      meta: {auth: true, rights: true, notStreaming: true},
      components: { default: Channels }
    },
    {
      path: 'packageOne/:id',
      name: 'packageOne',
      meta: {auth: true, rights: true, notStreaming: true},
      components: { default: PackageOne }
    }
  ]
};

// Melofone media menu  
let melofoneMedia = {
  path: '/media',
  component: DashboardLayout,
  redirect: '/media/ads',
  name: 'media',
  children: [
    {
      path: 'videos',
      name: 'videos',
      meta: {auth: true, notStreaming: true},
      components: { default: Videos }
    },
    {
      path: 'videosAds',
      name: 'videosAds',
      meta: {auth: true, notStreaming: true},
      components: { default: VideosAds }
    },
    {
      path: 'ads',
      name: 'ads',
      meta: {auth: true, notStreaming: true},
      components: { default: Ads }
    },
    {
      path: 'melodies',
      name: 'melodies',
      meta: {auth: true, notStreaming: true},
      components: { default: Melodies }
    },
    {
      path: 'playlists',
      name: 'playlists',
      meta: {auth: true, notStreaming: true},
      components: { default: Playlists }
    },
    {
      path: 'videosOne/:id',
      name: 'videosOne',
      meta: {auth: true, notStreaming: true},
      components: { default: VideosOne }
    },
    {
      path: 'videosAdsOne/:id',
      name: 'videosAdsOne',
      meta: {auth: true, notStreaming: true},
      components: { default: VideosAdsOne }
    },
    {
      path: 'adsOne/:id',
      name: 'adsOne',
      meta: {auth: true, notStreaming: true},
      components: { default: AdsOne }
    },
    {
      path: 'melodiesOne/:id',
      name: 'melodiesOne',
      meta: {auth: true, notStreaming: true},
      components: { default: MelodiesOne }
    },
    {
      path: 'playlistsOne/:id',
      name: 'playlistsOne',
      meta: {auth: true, notStreaming: true},
      components: { default: PlaylistsOne }
    },
    {
      path: 'wizard',
      name: 'wizard',
      meta: {auth: true, notStreaming: true},
      components: { default: Wizard }
    }
  ]
};

// Melofone equipment menu  
let melofoneEquip = {
  path: '/equipments',
  component: DashboardLayout,
  redirect: '/equipments/devices',
  name: 'equipments',
  children: [
    {
      path: 'devices',
      name: 'devices',
      meta: {auth: true, notStreaming: true},
      components: { default: Devices }
    },
    {
      path: 'groups',
      name: 'groups',
      meta: {auth: true, notStreaming: true},
      components: { default: Groups }
    },
    {
      path: 'devicesOne/:id',
      name: 'devicesOne',
      meta: {auth: true, notStreaming: true},
      components: { default: DevicesOne }
    },
    {
      path: 'groupsOne/:id',
      name: 'groupsOne',
      meta: {auth: true, notStreaming: true},
      components: { default: GroupsOne }
    }
  ]
};

// Melofone publication menu  
let melofonePub = {
  path: '/deployments',
  component: DashboardLayout,
  redirect: '/deployments/publications',
  name: 'deployments',
  children: [
    {
      path: 'publications',
      name: 'publications',
      meta: {auth: true, notStreaming: true},
      components: { default: Publications }
    },
    {
      path: 'publicationsOne/:id',
      name: 'publicationsOne',
      meta: {auth: true, notStreaming: true},
      components: { default: PublicationsOne }
    }
  ]
};

// Melofone home menu  
let melofoneHome = {
  path: '/home',
  component: DashboardLayout,
  redirect: '/home/overview',
  name: 'home',
  children: [
    {
      path: 'overview',
      name: 'overview',
      meta: {auth: true, notStreaming: true},
      components: { default: Dashboard }
    },
    {
      path: 'reports',
      name: 'reports',
      meta: {auth: true, notStreaming: true},
      components: { default: Reports }
    },
    {
      path: 'streaming/:id',
      name: 'streaming',
      meta: {auth: true},
      components: { default: Streaming }
    }
  ]
};

// Pages menu 
let pagesMenu = {
  path: '/pages',
  component: DashboardLayout,
  name: 'pages',
  redirect: '/pages/profile',
  children: [
    {
      path: 'profile',
      name: 'profile',
      meta: {auth: true},
      components: { default: Profile }
    },
    {
      path: 'rtl',
      name: 'RTL Page',
      meta: {auth: true},
      components: { default: RTL }
    }
  ]
};

// Autentification menu 
let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  redirect: '/login',
  children: [
    {
      path: 'login',
      name: 'Login',
      component: Login
    },
    // {
    //   path: 'register',
    //   name: 'Register',
    //   component: Register
    // },
    // {
    //   path: 'pricing',
    //   name: 'Pricing',
    //   component: Pricing
    // },
    {
      path: 'lock',
      name: 'Lock',
      meta: {auth: true},
      component: Lock
    }
  ]
};


const routes = [
  melofoneUpdates,
  melofoneTrash,
  melofoneSecurity,
  melofoneMedia,
  melofoneEquip,
  melofonePub,
  pagesMenu,
  authPages,
  melofoneHome,
  { path: '*', component: AuthLayout, children: [
    { path: '*',
      component: NotFound
    }]}
];

export default routes;
