import axios from 'axios'
import { responseCheck, errorAnswer } from "@/util/functions.js"

export default {
  actions: {
    //List of all groups
    async fetchGr({dispatch}, contentType) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/group/qlist/${contentType}`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Group by ID information
    async fetchGrById({dispatch}, uid) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/group/qcard/${uid}`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Edit Group status
    async editGr({dispatch}, uid) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/action/group/${uid}/changestate`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Add device to group
    async addToGr({dispatch}, {device, group}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/action/add/${device}/to/${group}`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Dell device from group
    async deleteFromGr({dispatch}, {device, group}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/action/remove/${device}/from/${group}`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Create Group
    async createGr({dispatch}, {formData, contentType}) {
      const json = JSON.stringify(formData);
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.post(`${key}/api/v2/group/add/${contentType}`, json, {
        headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json'
        }
      }) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Rename Group
    async renameGr({dispatch}, data) {
      const json = JSON.stringify(data);
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.post(`${key}/api/v2/group/edit`, json, {
        headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json'
        }
      }) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },
    
    //Delete Group
    async deleteGr({dispatch}, data) {
      const json = JSON.stringify(data);
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.post(`${key}/api/v2/group/delete`, json, {
        headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json'
        }
      }) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Delete Groups, more than one element at once
    async deleteGrMass({dispatch}, data) {
      const json = JSON.stringify(data);
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.post(`${key}/api/v2/group/massdelete`, json, {
        headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json'
        }
      }) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    }
  }
}