var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('sidebar-fixed-toggle-button'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground}},[_c('template',{slot:"links"},[(!_vm.streamingMenu)?_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.overview'),
          icon: 'tim-icons icon-atom',
          path: '/home/overview'
        }}}):_vm._e(),(!_vm.adminMenu && !_vm.streamingMenu)?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.media'),
        icon: 'tim-icons icon-volume-98' }}},[_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.videos'),
          path: '/media/videos' }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.videosAds'),
          path: '/media/videosAds' }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.melodies'),
          path: '/media/melodies' }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.ads'),
            path: '/media/ads'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.playlists'),
            path: '/media/playlists'
          }}})],1):_vm._e(),(!_vm.adminMenu && !_vm.streamingMenu)?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.equipments'),
        icon: 'tim-icons icon-laptop' }}},[_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.devices'),
          path: '/equipments/devices' }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.groups'),
          path: '/equipments/groups' }}})],1):_vm._e(),(!_vm.adminMenu && !_vm.streamingMenu)?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.publications'),
        icon: 'tim-icons icon-send',
        path: '/deployments/publications' }}}):_vm._e(),(!_vm.adminMenu && !_vm.streamingMenu)?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.reports'),
        icon: 'tim-icons icon-chart-bar-32',
        path: '/home/reports' }}}):_vm._e(),(_vm.streamingMenu)?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.streaming'),
        icon: 'tim-icons icon-wifi',
        path: '/home/streaming/' + this.userRights.linkedDeviceUid }}}):_vm._e(),(_vm.adminMenu)?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.tags'),
        icon: 'tim-icons icon-tag',
        path: '/security/tags'}}}):_vm._e(),(_vm.adminMenu)?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.confProfile'),
        icon: 'tim-icons icon-badge',
        path: '/security/profile'}}}):_vm._e(),(_vm.adminMenu)?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.updates'),
        icon: 'tim-icons icon-spaceship' }}},[_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.package'),
          path: '/updates/package' }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.packagePub'),
          path: '/updates/packagePub' }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.files'),
          path: '/updates/files' }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.channels'),
          path: '/updates/channels' }}})],1):_vm._e(),(_vm.adminMenu)?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.logging'),
        icon: 'tim-icons icon-paper' }}},[_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.logs'),
          path: '/security/logs'}}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.audit'),
          path: '/security/audit'}}})],1):_vm._e(),(_vm.adminMenu)?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.trash'),
        icon: 'tim-icons icon-trash-simple' }}},[_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.media'),
          path: '/trash/mediaTrash' }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.playlists'),
          path: '/trash/playlistsTrash' }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.publications'),
          path: '/trash/publicationsTrash' }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.devices'),
          path: '/trash/devicesTrash' }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.groups'),
          path: '/trash/groupsTrash' }}})],1):_vm._e()],1)],2),_c('sidebar-share',{attrs:{"background-color":_vm.sidebarBackground},on:{"update:backgroundColor":function($event){_vm.sidebarBackground=$event},"update:background-color":function($event){_vm.sidebarBackground=$event}}}),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }