import axios from 'axios'
import { responseCheck, errorAnswer } from "@/util/functions.js"

export default {
  actions: {
    // Send test alarm start
    async alarmStart({dispatch}, {uid, alerttype}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/device/alert/${uid}/sendbegin/${alerttype}`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    }, 

    // Send test alarm end
    async alarmEnd({dispatch}, {uid, alerttype}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/device/alert/${uid}/sendend/${alerttype}`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Get element tags
    async fetchTag({dispatch},  {uid, contentType}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/tag/${contentType}/${uid}`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Get curent volume for device
    async fetchCurentVol({dispatch},  {uid, resultpolicy}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/policy/${uid}/${resultpolicy}`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    }, 

    //Add tags to element
    async addTag({dispatch}, {uid, contentType, formData}) {
      const json = JSON.stringify(formData);
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.post(`${key}/api/v2/tag/${contentType}/${uid}/add`, json, {
        headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json'
        }
      }) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },
    
    //Edit element`s tags
    async editTag({dispatch}, {uid, contentType, formData}) {
      const json = JSON.stringify(formData);
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.post(`${key}/api/v2/tag/${contentType}/${uid}/edit`, json, {
        headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json'
        }
      }) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Delete tags from element
    async delTag({dispatch}, {uid, contentType, formData}) {
      const json = JSON.stringify(formData);
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.post(`${key}/api/v2/tag/${contentType}/${uid}/delete`, json, {
        headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json'
        }
      }) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Import tags list to system
    async importTag({dispatch, commit}, formData) {
      const json = JSON.stringify(formData);
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.post(`${key}/api/v2/tagext/import`, json, {
        headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json'
        },
        onUploadProgress: function( progressEvent ) {
            commit('setUpload', Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
        }
      }) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    }
  }
}