import axios from 'axios'
import { responseCheck, errorAnswer } from "@/util/functions.js"

export default {
  actions: {
    // List of deleted elements (accepted variants: track, playlist, deploy, playpoint, group)
    async fetchRecycle({dispatch}, contentType) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/recycle/${contentType}`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    // Restore selected element from recycle
    async trashRestore({dispatch}, {contentType, uid}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/recycle/${contentType}/${uid}/restore`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    }
  }
}