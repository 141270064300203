/*!

 =========================================================
 * Vue Black Dashboard PRO - v1.3.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vue-black-dashboard-pro
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch'
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import axios from 'axios'
import store from '@/store'
// import { errorAnswer } from "@/util/functions.js"

// router setup
import router from './routes/router';
import i18n from './i18n';
import './registerServiceWorker'

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);

//Avoid loss axios header during refresh page
const token = localStorage.getItem('user')
if (token) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(token).token
}

// ERROR - double notify when expired. Catch unauthorized error during work (token expired)
// axios.interceptors.response.use(undefined, function (error) {
//   if (error.response.status === 401) {
//     store.dispatch('auth_logout');
//     router.push('/login');
//     errorAnswer(error);
//   } else {
//     return Promise.reject(error);
//   }
// });
  

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  i18n,
  store
});
