import Vue from 'vue'
import i18n from '@/i18n.js'
import store from '@/store'
import router from '@/routes/router';

// Concat LTR and RTL string properly
// export function wrap_dir(dir, str) {
//   if (dir === 'rtl') return '\u202B' + str + '\u202C';
//   return '\u202A' + str + '\u202C';
// }

// Function for compare array of objects for Tags display properly
export function compare(a, b) {
  if ( a.key < b.key ){
    return -1;
  }
  if ( a.key > b.key ){
    return 1;
  }
  return 0;
}

// Function for store and it`s errors
export function responseCheck(response) {
  if (response.data || response.data === 0) {
    return response.data
  } else {
    throw new SyntaxError("Answer Data field is empty")
  }
}

// Function for showing errors
export function errorAnswer(error) {
  if (typeof error.response === 'undefined') {
    Vue.prototype.$notify({
      type: 'danger',
      title: i18n.t('notifications.fatalError'),
      message: i18n.t('notifications.fatalErrorCorsNet'),
      icon: 'tim-icons icon-settings'
    });
    throw new SyntaxError("No internet connection or CORS error");
  }
  else if (error.response.status === 400) {
    Vue.prototype.$notify({
      type: 'danger',
      message: i18n.t('notifications.login'),
      icon: 'tim-icons icon-key-25'
    });
    throw new SyntaxError("Username or password is incorrect");
  } 
  else if (error.response.status === 401) {
    store.dispatch('auth_logout');
    router.push('/login');

    Vue.prototype.$notify({
      type: 'danger',
      message: i18n.t('notifications.tokenExpired'),
      icon: 'tim-icons icon-time-alarm'
    });
    throw new SyntaxError("Token expired");
  }
  else {
    Vue.prototype.$notify({
      type: 'danger',
      title: i18n.t('notifications.fatalError'),
      message: i18n.t('notifications.fatalErrorText') + error.response.status,
      icon: 'tim-icons icon-settings'
    })
    throw new SyntaxError("Error code №" + error.response.status);
  }
}

// Function for serch optimization for every page
export function search(value, tableData, searchObj, ...fieldsForSearch) {
  let result = tableData;

  if (value !== '') {
    // Step 1: Remove spaces and tokenise each individual word, but keep words that are between " and " as a single token
    const tokenisedSearchQuery = value.trim().match(/("[^"]*?"|[^"\s]+)+(?=\s*|\s*$)/g);

    result = searchObj.search(
      // Step 2: use our tokeniseStringWithQuotesBySpaces tokeniser + customised Fuse query (using $and to join each of our tokens, then $or for the different fields) for the search
      {
        $and: tokenisedSearchQuery.map((searchToken) => {
          const orFields =[]
          fieldsForSearch.forEach(function(item, i, arr) {
            let temp = {
              $path: [item],
              $val: searchToken
            };
            orFields.push(temp);
          })

          return {
            $or: orFields,
          };
        }),
      }
    );
    return result.map(character => character.item);
  } else {
    return [];
  }
}