import axios from 'axios'
import { responseCheck, errorAnswer } from "@/util/functions.js"

export default {
  actions: {
    //Played content chart
    async melodyChart({dispatch}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/chart/playtrack-detailed/cached`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    }, 

    //Active devices chart
    async deviceChart({dispatch}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/chart/active-devices/cached`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Free space on all devices chart
    async spaceChart({dispatch}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/chart/free-space/cached`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Load average 1m on all devices chart
    async la1Chart({dispatch}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/chart/la-1min/cached`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Load average 5m on all devices chart
    async la5Chart({dispatch}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/chart/la-5min/cached`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Load average 15m on all devices chart
    async la15Chart({dispatch}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/chart/la-15min/cached`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //All metrics
    async allMetrics({dispatch}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/metricks/quick/cached`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Subscription limits
    async subscription({dispatch}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/metricks/limits`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Report Statistic with number of days before today
    // async repStatistic({dispatch}, {mode, uid, days}) {
    //   const key = process.env.VUE_APP_PROJECT;
    //   await axios.get(`${key}/api/v2/report/stat/${mode}/${uid}/${days}`, {responseType: 'blob'}) 
    //     .then(function (response){
    //       if (!window.navigator.msSaveOrOpenBlob){
    //         // BLOB NAVIGATOR
    //         const url = window.URL.createObjectURL(new Blob([response.data]));
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.setAttribute('download', response.headers["content-disposition"].split("filename=").pop().split('; filename')[0]);
    //         document.body.appendChild(link);
    //         link.click();
    //       }else{
    //         // BLOB FOR EXPLORER 11
    //         const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]), response.headers["content-disposition"].split("filename=")[1]);
    //       }
    //     })
    //     .catch(error => errorAnswer(error));
    // },

    //Report Statistic with Date
    async repStatisticDate({dispatch}, {mode, uid, fromdate, todate, regionalTime}) {
      const key = process.env.VUE_APP_PROJECT;
      await axios.get(`${key}/api/v2/report/statetx/${mode}/${uid}/from/${fromdate}/to/${todate}/regiontime/${regionalTime}`, {responseType: 'blob'}) 
        .then(function (response){
          if (!window.navigator.msSaveOrOpenBlob){
            // BLOB NAVIGATOR
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', response.headers["content-disposition"].split("filename=").pop().split('; filename')[0]);
            document.body.appendChild(link);
            link.click();
          }else{
            // BLOB FOR EXPLORER 11
            const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]), response.headers["content-disposition"].split("filename=")[1]);
          }
        })
        .catch(error => errorAnswer(error));
    },

    //Report R1 with Date
    async repR1date({dispatch}, {trackuid, playpointuid, playlistuid, fromdate, todate, regionalTime}) {
      const key = process.env.VUE_APP_PROJECT;
      await axios.get(`${key}/api/v2/report/r1ext/${trackuid}/${playpointuid}/${playlistuid}/from/${fromdate}/to/${todate}/regiontime/${regionalTime}`, {responseType: 'blob'}) 
        .then(function (response){
          if (!window.navigator.msSaveOrOpenBlob){
            // BLOB NAVIGATOR
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', response.headers["content-disposition"].split("filename=").pop().split('; filename')[0]);
            document.body.appendChild(link);
            link.click();
          }else{
            // BLOB FOR EXPLORER 11
            const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]), response.headers["content-disposition"].split("filename=")[1]);
          }
        })
        .catch(error => errorAnswer(error));
    },

    //Report R1 with number of days before today
    // async repR1({dispatch}, {trackuid, playpointuid, playlistuid, period}) {
    //   const key = process.env.VUE_APP_PROJECT;
    //   await axios.get(`${key}/api/v2/report/r1/${trackuid}/${playpointuid}/${playlistuid}/${period}`, {responseType: 'blob'}) 
    //     .then(function (response){
    //       if (!window.navigator.msSaveOrOpenBlob){
    //         // BLOB NAVIGATOR
    //         const url = window.URL.createObjectURL(new Blob([response.data]));
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.setAttribute('download', response.headers["content-disposition"].split("filename=").pop().split('; filename')[0]);
    //         document.body.appendChild(link);
    //         link.click();
    //       }else{
    //         // BLOB FOR EXPLORER 11
    //         const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]), response.headers["content-disposition"].split("filename=")[1]);
    //       }
    //     })
    //     .catch(error => errorAnswer(error));
    // },

    //Report R2
    async repR2({dispatch}) {
      const key = process.env.VUE_APP_PROJECT;
      await axios.get(`${key}/api/v2/report/r2`, {responseType: 'blob'}) 
        .then(function (response){
          if (!window.navigator.msSaveOrOpenBlob){
            // BLOB NAVIGATOR
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', response.headers["content-disposition"].split("filename=").pop().split('; filename')[0]);
            document.body.appendChild(link);
            link.click();
          }else{
            // BLOB FOR EXPLORER 11
            const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]), response.headers["content-disposition"].split("filename=")[1]);
          }
        })
        .catch(error => errorAnswer(error));
    },

    //Report R3
    async repR3({dispatch}) {
      const key = process.env.VUE_APP_PROJECT;
      await axios.get(`${key}/api/v2/report/r3`, {responseType: 'blob'}) 
        .then(function (response){
          if (!window.navigator.msSaveOrOpenBlob){
            // BLOB NAVIGATOR
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', response.headers["content-disposition"].split("filename=").pop().split('; filename')[0]);
            document.body.appendChild(link);
            link.click();
          }else{
            // BLOB FOR EXPLORER 11
            const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]), response.headers["content-disposition"].split("filename=")[1]);
          }
        })
        .catch(error => errorAnswer(error));
    },

    //Report S1 with number of days before today
    // async repS1({dispatch}, days) {
    //   const key = process.env.VUE_APP_PROJECT;
    //   await axios.get(`${key}/api/v2/report/s1/${days}`, {responseType: 'blob'}) 
    //     .then(function (response){
    //       if (!window.navigator.msSaveOrOpenBlob){
    //         // BLOB NAVIGATOR
    //         const url = window.URL.createObjectURL(new Blob([response.data]));
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.setAttribute('download', response.headers["content-disposition"].split("filename=").pop().split('; filename')[0]);
    //         document.body.appendChild(link);
    //         link.click();
    //       }else{
    //         // BLOB FOR EXPLORER 11
    //         const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]), response.headers["content-disposition"].split("filename=")[1]);
    //       }
    //     })
    //     .catch(error => errorAnswer(error));
    // },

    //Report S2 with number of days before today
    // async repS2({dispatch}, days) {
    //   const key = process.env.VUE_APP_PROJECT;
    //   await axios.get(`${key}/api/v2/report/s2/${days}`, {responseType: 'blob'}) 
    //     .then(function (response){
    //       if (!window.navigator.msSaveOrOpenBlob){
    //         // BLOB NAVIGATOR
    //         const url = window.URL.createObjectURL(new Blob([response.data]));
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.setAttribute('download', response.headers["content-disposition"].split("filename=").pop().split('; filename')[0]);
    //         document.body.appendChild(link);
    //         link.click();
    //       }else{
    //         // BLOB FOR EXPLORER 11
    //         const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]), response.headers["content-disposition"].split("filename=")[1]);
    //       }
    //     })
    //     .catch(error => errorAnswer(error));
    // },

    //Report S1 with Date
    async repS1date({dispatch}, {fromdate, todate, regionalTime}) {
      const key = process.env.VUE_APP_PROJECT;
      await axios.get(`${key}/api/v2/report/s1ext/from/${fromdate}/to/${todate}/regiontime/${regionalTime}`, {responseType: 'blob'}) 
        .then(function (response){
          if (!window.navigator.msSaveOrOpenBlob){
            // BLOB NAVIGATOR
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', response.headers["content-disposition"].split("filename=").pop().split('; filename')[0]);
            document.body.appendChild(link);
            link.click();
          }else{
            // BLOB FOR EXPLORER 11
            const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]), response.headers["content-disposition"].split("filename=")[1]);
          }
        })
        .catch(error => errorAnswer(error));
    },

    //Report S2 with Date
    async repS2date({dispatch}, {fromdate, todate, regionalTime}) {
      const key = process.env.VUE_APP_PROJECT;
      await axios.get(`${key}/api/v2/report/s2ext/from/${fromdate}/to/${todate}/regiontime/${regionalTime}`, {responseType: 'blob'}) 
        .then(function (response){
          if (!window.navigator.msSaveOrOpenBlob){
            // BLOB NAVIGATOR
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', response.headers["content-disposition"].split("filename=").pop().split('; filename')[0]);
            document.body.appendChild(link);
            link.click();
          }else{
            // BLOB FOR EXPLORER 11
            const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]), response.headers["content-disposition"].split("filename=")[1]);
          }
        })
        .catch(error => errorAnswer(error));
    },

    //Report A1 with Date
    async repA1date({dispatch}, {fromdate, todate, regionalTime}) {
      const key = process.env.VUE_APP_PROJECT;
      await axios.get(`${key}/api/v2/report/alert1/from/${fromdate}/to/${todate}/regiontime/${regionalTime}`, {responseType: 'blob'}) 
        .then(function (response){
          if (!window.navigator.msSaveOrOpenBlob){
            // BLOB NAVIGATOR
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', response.headers["content-disposition"].split("filename=").pop().split('; filename')[0]);
            document.body.appendChild(link);
            link.click();
          }else{
            // BLOB FOR EXPLORER 11
            const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]), response.headers["content-disposition"].split("filename=")[1]);
          }
        })
        .catch(error => errorAnswer(error));
    },

    //Report A2 with Date
    async repA2date({dispatch}, {fromdate, todate, regionalTime}) {
      const key = process.env.VUE_APP_PROJECT;
      await axios.get(`${key}/api/v2/report/alert2/from/${fromdate}/to/${todate}/regiontime/${regionalTime}`, {responseType: 'blob'}) 
        .then(function (response){
          if (!window.navigator.msSaveOrOpenBlob){
            // BLOB NAVIGATOR
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', response.headers["content-disposition"].split("filename=").pop().split('; filename')[0]);
            document.body.appendChild(link);
            link.click();
          }else{
            // BLOB FOR EXPLORER 11
            const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]), response.headers["content-disposition"].split("filename=")[1]);
          }
        })
        .catch(error => errorAnswer(error));
    },

    //Report A3 with Date
    async repA3date({dispatch}, {fromdate, todate, regionalTime}) {
      const key = process.env.VUE_APP_PROJECT;
      await axios.get(`${key}/api/v2/report/alert3/from/${fromdate}/to/${todate}/regiontime/${regionalTime}`, {responseType: 'blob'}) 
        .then(function (response){
          if (!window.navigator.msSaveOrOpenBlob){
            // BLOB NAVIGATOR
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', response.headers["content-disposition"].split("filename=").pop().split('; filename')[0]);
            document.body.appendChild(link);
            link.click();
          }else{
            // BLOB FOR EXPLORER 11
            const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]), response.headers["content-disposition"].split("filename=")[1]);
          }
        })
        .catch(error => errorAnswer(error));
    },

    //Save JSON to disk
    async saveJSON({dispatch}, {file, name}) { 
      if (!window.navigator.msSaveOrOpenBlob){
        // BLOB NAVIGATOR
        const url = window.URL.createObjectURL(new Blob([file], { type: 'application/json' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
      }else{
        // BLOB FOR EXPLORER 11
        const url = window.navigator.msSaveOrOpenBlob(new Blob([file]), name);
      }        
    },

    //Report for all Tags on Devices
    async repAllDevTags({dispatch}) {
      const key = process.env.VUE_APP_PROJECT;
      await axios.get(`${key}/api/v2/report/playpoints/tags`, {responseType: 'blob'}) 
        .then(function (response){
          if (!window.navigator.msSaveOrOpenBlob){
            // BLOB NAVIGATOR
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', response.headers["content-disposition"].split("filename=").pop().split('; filename')[0]);
            document.body.appendChild(link);
            link.click();
          }else{
            // BLOB FOR EXPLORER 11
            const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]), response.headers["content-disposition"].split("filename=")[1]);
          }
        })
        .catch(error => errorAnswer(error));
    },
  }
}