<template>
  <div :class="$rtl.isRTL ? 'fixed-plugin-left' : 'fixed-plugin'" v-click-outside="closeDropDown">
    <div class="dropdown show-dropdown" :class="{ show: isOpen }">
      <a data-toggle="dropdown" class="settings-icon">
        <img src="@/assets/img/cog.svg" style="height:60%; width:60%" @click="toggleDropDown">
      </a>
      <ul class="dropdown-menu" :class="{ show: isOpen }">
        <li class="header-title"> {{ $t('sidebarSharePugin.background') }} </li>
        <li class="adjustments-line">
          <a class="switch-trigger background-color">
            <div class="badge-colors text-center">
              <span
                v-for="item in sidebarColors"
                :key="item.color"
                class="badge filter"
                :class="[`badge-${item.color}`, { active: item.active }]"
                :data-color="item.color"
                @click="changeSidebarBackground(item);"
              ></span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>

        <li class="header-title"> {{ $t('sidebarSharePugin.sdm') }} </li>
        <li class="adjustments-line">
          <div class="togglebutton switch-sidebar-mini" v-if="!isMobile">
            <span class="label-switch"> {{ $t('sidebarSharePugin.modeF') }} </span>
            <base-switch
              v-model="sidebarMini"
              @input="minimizeSidebar"
            ></base-switch>
            <span class="label-switch label-right"> {{ $t('sidebarSharePugin.modeM') }} </span>
          </div>

          <!-- <div class="togglebutton switch-sidebar-mini mt-2">
            <span class="label-switch">Лево</span>
            <base-switch
              v-model="rtlMode"
              @input="rtl"
            ></base-switch>
            <span class="label-switch label-right">Право</span>
          </div> -->

          <div class="togglebutton switch-change-color mt-2">
            <span class="label-switch"> {{ $t('sidebarSharePugin.modeL') }} </span>
            <base-switch v-model="darkMode" @input="toggleMode"></base-switch>
            <span class="label-switch label-right"> {{ $t('sidebarSharePugin.modeD') }} </span>
          </div>
        </li>
        
        <li class="header-title mt-4"> {{ $t('sidebarSharePugin.support') }} </li>
        <li class="button-container">
          <!-- <a
            :href="`${manualLink}/files/User_manual_Melofone_${this.$i18n.locale}.pdf`"
            target="_blank"
            rel="noopener"
            class="btn btn-default btn-block btn-round"
          >
           {{ $t('sidebarSharePugin.document') }}
          </a> -->
          <div style="display: flex; justify-content: center;">
            <base-button round class="animation-on-hover" @click="instuctions()" style="max-width: 120%;">{{ $t('sidebarSharePugin.document') }}</base-button>
          </div>
          
          <div class="contacts mt-2">
            <base-button class="btn-neutral" icon round>
              <a 
                href= "mailto:support@melofone.media"
              >
                <img src="@/assets/img/email.svg" style="height:85%; width:85%" alt="Mail">
              </a>
            </base-button>
            <base-button class="btn-neutral" icon round>
              <a 
                href="https://telegram.me/MelofoneBot"
                target="_blank"
              >
                <img src="@/assets/img/telegram.svg" alt="Telegram">
              </a>
            </base-button>
            <base-button class="btn-neutral" icon round disabled>
              <img src="@/assets/img/whatsapp.svg" alt="Whatsapp">
            </base-button>
          </div>
        </li>

      </ul>
    </div>
  </div>
</template>
<script>
  import { BaseSwitch } from 'src/components';

  export default {
    name: 'sidebar-share',
    components: {
      BaseSwitch
    },
    props: {
      backgroundColor: String
    },
    data() {
      return {
        manualLink: process.env.VUE_APP_MANUAL,
        sidebarMini: (JSON.parse(localStorage.getItem('personalization')).menuType === 'true' 
          || JSON.parse(localStorage.getItem('personalization')).menuType === true 
          || JSON.parse(localStorage.getItem('personalization')).menuType === null) ? true : false,
        darkMode: (JSON.parse(localStorage.getItem('personalization')).mode === 'true' 
          || JSON.parse(localStorage.getItem('personalization')).mode === true
          || JSON.parse(localStorage.getItem('personalization')).mode === null) ? true : false,
        rtlMode: true,
        isOpen: false,
        sidebarColors: [
          // { color: 'danger', active: false, value: 'red' },
          { color: 'warning', active: false, value: 'orange' },
          { color: 'citrus', active: false, value: 'citrus' },
          // { color: 'vue', active: false, value: 'vue' },
          { color: 'success', active: false, value: 'green' },
          { color: 'info', active: true, value: 'blue' },
          { color: 'primary', active: false, value: 'primary' }
        ]
      };
    },
    computed: {

      // Device with small screen detection for reduce data in tables
      isMobile() {
        return window.matchMedia("only screen and (max-width: 480px)").matches;
      }
    },
    methods: {
      instuctions() {
        switch (this.$i18n.locale) {
          case 'ru':
            window.open(`${this.manualLink}/files/User_manual_Melofone_ru.pdf`, '_blank');
            break;
          case 'en':
            window.open(`${this.manualLink}/files/User_manual_Melofone_en.pdf`, '_blank');
            break;
          default:
            window.open(`${this.manualLink}/files/User_manual_Melofone_none.pdf`, '_blank');
        }
        // Common line for all languges
        // window.open(`${this.manualLink}/files/User_manual_Melofone_${this.$i18n.locale}.pdf`, '_blank');
      },
      toggleDropDown() {
        this.isOpen = !this.isOpen;
      },
      closeDropDown() {
        this.isOpen = false;
      },
      toggleList(list, itemToActivate) {
        list.forEach(listItem => {
          listItem.active = false;
        });
        itemToActivate.active = true;
      },
      changeSidebarBackground(item) {
        this.$emit('update:backgroundColor', item.value);
        this.$store.commit('setColor', item.color);
        let updateLS = JSON.parse(localStorage.getItem('personalization'));
        updateLS.color = item.color;
        localStorage.setItem('personalization', JSON.stringify(updateLS));
        this.toggleList(this.sidebarColors, item);
      },

      // Black or white menu mode
      toggleMode(type) {
        let docClasses = document.body.classList;
        if (type) {
          docClasses.remove('white-content');
          let updateLS = JSON.parse(localStorage.getItem('personalization'));
          updateLS.mode = this.darkMode;
          localStorage.setItem('personalization', JSON.stringify(updateLS));
        } else {
          docClasses.add('white-content');
          let updateLS = JSON.parse(localStorage.getItem('personalization'));
          updateLS.mode = this.darkMode;
          localStorage.setItem('personalization', JSON.stringify(updateLS));
        }
      },

      // Sidebar mode
      minimizeSidebar() {
        // Remove this code if you don't want to display notifications while minimizing sidebar
        // let isMinimizedText = this.$sidebar.isMinimized ? this.$t('notifications.deactivated') : this.$t('notifications.activated');
        
        // this.$notify({
        //   type: 'success',
        //   message: this.$t('notifications.sidebar') + ` `+`${isMinimizedText}`,
        //   icon: 'tim-icons icon-bell-55'
        // });

        this.$sidebar.toggleMinimize();
        let updateLS = JSON.parse(localStorage.getItem('personalization'));
        updateLS.menuType = this.sidebarMini;
        localStorage.setItem('personalization', JSON.stringify(updateLS));
      },

      // Right/left menu
      rtl(type) {
        if (type) {
          this.$rtl.enableRTL();
        } else {
          this.$rtl.disableRTL();
        }
      },
    },
    mounted() {
      
      // Set user personalized Mode
      this.toggleMode(this.darkMode);
      
      // Set user personalized Sidebar color
      for (var item in this.sidebarColors) {
        if (this.sidebarColors[item].color === JSON.parse(localStorage.getItem('personalization')).color) {
          this.sidebarColors[item].active = true;
          this.changeSidebarBackground(this.sidebarColors[item]);
        } else {this.sidebarColors[item].active = false}
      }
      
      // Set user personalized Sidebar mode
      if (this.sidebarMini === this.$sidebar.isMinimized) {
        this.minimizeSidebar();
      }

    }
  };
</script>
<style scoped lang="scss">
  @import '~@/assets/sass/dashboard/custom/variables';

  .settings-icon {
    cursor: pointer;
  }

  .badge-vue {
    background-color: $vue;
  }

  .contacts {
    display: flex;
    flex-direction: row;
    justify-content: space-around
  }
</style>
