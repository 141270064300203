import axios from 'axios'
import { responseCheck, errorAnswer } from "@/util/functions.js"

export default {
  actions: {
    //List of all Publications for start Melodies page
    async fetchPub({dispatch}, contentType) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/deploy/qlist/${contentType}`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    }, 

    //Publication by ID information
    async fetchPubById({dispatch}, uid) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/deploy/qcard/${uid}`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },
    
    //Edit Publication status
    async editPub({dispatch}, uid) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/action/deploy/${uid}/changestate`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Delayed Publication
    async delayedActivation({dispatch}, {uid, startdatetime}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/action/deploy/${uid}/delaystart/${startdatetime}`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //List of Groups for Publication 
    async fetcListGrForPub({dispatch}, contentType) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/selectlist/devicegroup/${contentType}`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //List of Playlists for Publication
    async fetcListPlForPub({dispatch}, contentType) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/selectlist/playlist/${contentType}`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Unused publications delete
    async unusedPubDel({dispatch}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/deploy/deleteunused`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Create Publication
    async createPub({dispatch}, {formData, contentType}) {
      const json = JSON.stringify(formData);
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.post(`${key}/api/v2/deploy/add/${contentType}`, json, {
        headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json'
        }
      }) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Rename Publication
    async renamePub({dispatch}, data) {
      const json = JSON.stringify(data);
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.post(`${key}/api/v2/deploy/edit`, json, {
        headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json'
        }
      }) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },
    
    //Delete Publication
    async deletePub({dispatch}, data) {
      const json = JSON.stringify(data);
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.post(`${key}/api/v2/deploy/delete`, json, {
        headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json'
        }
      }) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Delete Publication, more than one element at once
    async deletePubMass({dispatch}, data) {
      const json = JSON.stringify(data);
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.post(`${key}/api/v2/deploy/massdelete`, json, {
        headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json'
        }
      }) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    }
  }
}