<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <sidebar-fixed-toggle-button />
    <side-bar
      :background-color="sidebarBackground"
    >
      <template  slot="links"> <!--slot-scope="props"-->
       
        <!--Dashboard menu block-->
        <sidebar-item
          :link="{
            name: $t('sidebar.overview'),
            icon: 'tim-icons icon-atom',
            path: '/home/overview'
          }"
          v-if="!streamingMenu"
        >
        </sidebar-item>

        <!--Melodies menu block-->
        <sidebar-item
          :link="{ name: $t('sidebar.media'),
          icon: 'tim-icons icon-volume-98' }"
          v-if="!adminMenu && !streamingMenu"
        >
           <sidebar-item
            :link="{ name: $t('sidebar.videos'),
            path: '/media/videos' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.videosAds'),
            path: '/media/videosAds' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.melodies'),
            path: '/media/melodies' }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.ads'),
              path: '/media/ads'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.playlists'),
              path: '/media/playlists'
            }"
          ></sidebar-item>
        </sidebar-item>
        
        <!--Equipment menu block-->
        <sidebar-item
          :link="{ name: $t('sidebar.equipments'),
          icon: 'tim-icons icon-laptop' }"
          v-if="!adminMenu && !streamingMenu"
        >
          <sidebar-item
            :link="{ name: $t('sidebar.devices'),
            path: '/equipments/devices' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.groups'),
            path: '/equipments/groups' }"
          ></sidebar-item>
        </sidebar-item>
        
        <!--Publications menu block-->
        <sidebar-item
          :link="{ name: $t('sidebar.publications'),
          icon: 'tim-icons icon-send',
          path: '/deployments/publications' }"
          v-if="!adminMenu && !streamingMenu"
        ></sidebar-item>
        
        <!--Reports menu block-->
        <sidebar-item
          :link="{ name: $t('sidebar.reports'),
          icon: 'tim-icons icon-chart-bar-32',
          path: '/home/reports' }"
          v-if="!adminMenu && !streamingMenu"
        ></sidebar-item>

        <!--Streaming playlist online-->
        <sidebar-item
          :link="{ name: $t('sidebar.streaming'),
          icon: 'tim-icons icon-wifi',
          path: '/home/streaming/' + this.userRights.linkedDeviceUid }"
          v-if="streamingMenu"
        ></sidebar-item>

        <!--Tags menu block-->
        <sidebar-item
          :link="{ name: $t('sidebar.tags'),
          icon: 'tim-icons icon-tag',
          path: '/security/tags'}"
          v-if="adminMenu"
        ></sidebar-item>

        <!--Profile menu block-->
        <sidebar-item
          :link="{ name: $t('sidebar.confProfile'),
          icon: 'tim-icons icon-badge',
          path: '/security/profile'}"
          v-if="adminMenu"
        ></sidebar-item>
        
        <!--Update menu block-->
        <sidebar-item
          :link="{ name: $t('sidebar.updates'),
          icon: 'tim-icons icon-spaceship' }"
          v-if="adminMenu"
        >
          <sidebar-item
            :link="{ name: $t('sidebar.package'),
            path: '/updates/package' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.packagePub'),
            path: '/updates/packagePub' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.files'),
            path: '/updates/files' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.channels'),
            path: '/updates/channels' }"
          ></sidebar-item>
        </sidebar-item>
        
        <!--Logs menu block-->
        <sidebar-item
          :link="{ name: $t('sidebar.logging'),
          icon: 'tim-icons icon-paper' }"
          v-if="adminMenu"
        >
          <sidebar-item
            :link="{ name: $t('sidebar.logs'),
            path: '/security/logs'}"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.audit'),
            path: '/security/audit'}"
          ></sidebar-item>
        </sidebar-item>

        <!--Trash menu block-->
        <sidebar-item
          :link="{ name: $t('sidebar.trash'),
          icon: 'tim-icons icon-trash-simple' }"
          v-if="adminMenu"
        >
          <sidebar-item
            :link="{ name: $t('sidebar.media'),
            path: '/trash/mediaTrash' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.playlists'),
            path: '/trash/playlistsTrash' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.publications'),
            path: '/trash/publicationsTrash' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.devices'),
            path: '/trash/devicesTrash' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.groups'),
            path: '/trash/groupsTrash' }"
          ></sidebar-item>
        </sidebar-item>

      </template>
    </side-bar>
    
    <!--Share plugin (for demo purposes). You can remove it if don't plan on using it-->
    <sidebar-share :background-color.sync="sidebarBackground"> </sidebar-share>
    <div class="main-panel" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>
      <router-view name="header"></router-view>
      
      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
                
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>  
      </div>         
    </div>    
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import SidebarShare from '../Layout/SidebarSharePlugin';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from '../Layout/DashboardNavbar.vue';
import ContentFooter from '../Layout/ContentFooter.vue';
import DashboardContent from '../Layout/Content.vue';
import SidebarFixedToggleButton from '../Layout/SidebarFixedToggleButton.vue';
import { SlideYDownTransition, ZoomCenterTransition } from 'vue2-transitions';

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    SidebarFixedToggleButton,
    DashboardContent,
    SlideYDownTransition,
    ZoomCenterTransition,
    SidebarShare
  },
  data() {
    return {
      adminMenu: false,
      streamingMenu: false,
      sidebarBackground: 'blue' //vue|blue|orange|green|red|primary
    };
  },

  computed: {
    userRights() {
      return JSON.parse(localStorage.getItem('user'))
    }
  },

  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar('sidebar');
        initScrollbar('main-panel');
        initScrollbar('sidebar-wrapper');

        docClasses.add('perfect-scrollbar-on');
      } else {
        docClasses.add('perfect-scrollbar-off');
      }
    }
  },
  async mounted() {
    this.adminMenu = JSON.parse(localStorage.getItem('user')).role === "Admin" ? true : false;
    this.streamingMenu = JSON.parse(localStorage.getItem('user')).role === "Streamer" ? true : false;
  },
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}

// Chrome/Edge/Safari hide scrollbar
::-webkit-scrollbar {
    display: none;
}

// IE/Firefox hide scrollbar
* {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

</style>
