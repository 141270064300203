export default {
    state: {
        colorUnification: 'info',
        uploading: 0,
        devFilter: '',
        plFilter: '',
        plType: 'AUDIO-SMART',
        workTime: 86400,
        melTime: 360,
        adsTime: 900,
        adsList: [],
        melList: [],
        finishList: [],
        startDay: '00:00',
        endDay: '23:59',
        smartPlUid: '',
        smartPlName: '',
        smartPlDescr: ''
    },
    mutations: {
        setColor(state, colorUnification) {
            state.colorUnification = colorUnification
        },
        setUpload(state, uploading) {
            state.uploading = uploading
        },
        setDevFilter(state, devFilter) {
            state.devFilter = devFilter
        },
        setPlFilter(state, plFilter) {
            state.plFilter = plFilter
        },
        setPlType(state, plType) {
            state.plType = plType
        },
        setWorkTime(state, workTime) {
            state.workTime = workTime
        },
        setMelTime(state, melTime) {
            state.melTime = melTime
        },
        setAdsTime(state, adsTime) {
            state.adsTime = adsTime
        },
        setAdsList(state, adsList) {
            state.adsList = adsList
        },
        setMelList(state, melList) {
            state.melList = melList
        },
        setStartDay(state, startDay) {
            state.startDay = startDay
        },
        setEndDay(state, endDay) {
            state.endDay = endDay
        },
        setFinishList(state, finishList) {
            state.finishList = finishList
        },
        setSmartPlUid(state, smartPlUid) {
            state.smartPlUid = smartPlUid
        },
        setSmartPlName(state, smartPlName) {
            state.smartPlName = smartPlName
        },
        setSmartPlDescr(state, smartPlDescr) {
            state.smartPlDescr = smartPlDescr
        }           
    },
    actions: {
      
    },
    getters: {
        colorUnification: s => s.colorUnification,
        uploading: s => s.uploading,
        devFilter: s => s.devFilter,
        plFilter: s => s.plFilter,
        plType: s => s.plType,
        workTime: s => s.workTime,
        melTime: s => s.melTime,
        adsTime: s => s.adsTime,
        adsList: s => s.adsList,
        melList: s => s.melList,
        startDay: s => s.startDay,
        endDay: s => s.endDay,
        finishList: s => s.finishList,
        smartPlUid: s => s.smartPlUid,
        smartPlName: s => s.smartPlName,
        smartPlDescr: s => s.smartPlDescr
    }
  }
  