<template>
  <div>
    <notifications></notifications>
    <base-nav
      v-model="showMenu"
      type="white"
      :transparent="true"
      menu-classes="justify-content-end"
      class="auth-navbar fixed-top"
    >

      <ul class="navbar-nav">

        <router-link to="/login" custom v-slot="{ href }">
          <li class="nav-item">
            <a :href="href" class="nav-link">
              <i class="tim-icons icon-single-02"></i> 
              {{ $t('sidebar.login') }} 
            </a>
          </li>
        </router-link>
        
        <li class="nav-item">
          <a href="https://melofone.media/" class="nav-link">
            <i class="tim-icons icon-coins"></i> 
            {{ $t('sidebar.pricing') }} 
          </a>
        </li>

        <!-- Language select --> 
        <base-dropdown
        tag="li"
        :menu-on-right="!$rtl.isRTL"
        title-tag="a"
        title-classes="nav-link"
        class="nav-item"        
        >
          <template
            slot="title"
          >
            <i class="tim-icons icon-world"></i>
          </template>
          <li>
            <a class="nav-item dropdown-item" @click="$i18n.locale = 'en', setRTL('ltr')"><img class="flags" src="@/assets/img/gb.svg" alt="EN"></a>
          </li>
          <li>
            <a class="nav-item dropdown-item" @click="$i18n.locale = 'ru', setRTL('ltr')"><img class="flags" src="@/assets/img/ru.svg" alt="RU"></a>
          </li>
          <li>
            <a class="nav-item dropdown-item" @click="$i18n.locale = 'de', setRTL('ltr')"><img class="flags" src="@/assets/img/de.svg" alt="DE"></a>
          </li>
          <li>
            <a class="nav-item dropdown-item" @click="$i18n.locale = 'ua', setRTL('ltr')"><img class="flags" src="@/assets/img/ua.svg" alt="UA"></a>
          </li>
          <li>
            <a class="nav-item dropdown-item" @click="$i18n.locale = 'cn', setRTL('ltr')"><img class="flags" src="@/assets/img/cn.svg" alt="CN"></a>
          </li>
          <li>
            <a class="nav-item dropdown-item" @click="$i18n.locale = 'ar', setRTL('rtl')"><img class="flags" src="@/assets/img/sa.svg" alt="AR"></a>
          </li>
          <li>
            <a class="nav-item dropdown-item" @click="$i18n.locale = 'pt', setRTL('ltr')"><img class="flags" src="@/assets/img/pt.svg" alt="PT"></a>
          </li>
          <li>
            <a class="nav-item dropdown-item" @click="$i18n.locale = 'es', setRTL('ltr')"><img class="flags" src="@/assets/img/es.svg" alt="ES"></a>
          </li>
          <li>
            <a class="nav-item dropdown-item" @click="$i18n.locale = 'fr', setRTL('ltr')"><img class="flags" src="@/assets/img/fr.svg" alt="FR"></a>
          </li>
        </base-dropdown>   
      </ul>     
    </base-nav>

    <div class="wrapper wrapper-full-page">
      <div class="full-page" :class="pageClass">
        <div class="content">
          <zoom-center-transition
            :duration="pageTransitionDuration"
            mode="out-in"
          >
            <router-view></router-view>
          </zoom-center-transition>
        </div>
        <footer class="footer">
          <div class="container-fluid">
            <nav>
              <ul class="nav">
                <li class="nav-item">
                  <a
                    href="http://melofone.media"
                    target="_blank"
                    rel="noopener"
                    class="nav-link"
                  >
                    Melofone Team
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    href="http://melofone.media"
                    target="_blank"
                    rel="noopener"
                    class="nav-link"
                  >
                    {{ $t('footer.about') }}
                  </a>
                </li>
              </ul>
            </nav>
            <div class="copyright">
              &copy; {{ year }}, 
              <a
                href="http://melofone.media"
                target="_blank"
                rel="noopener"
                >Melofone Team </a>
              {{ $t('footer.foot1') }} <i class="tim-icons icon-heart-2"></i> {{ $t('footer.foot2') }}
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
import { BaseNav } from 'src/components';
import { ZoomCenterTransition } from 'vue2-transitions';

export default {
  components: {
    BaseNav,
    ZoomCenterTransition
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'black'
    }
  },
  data() {
    return {
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: 'login-page'
    };
  },
  computed: {
    title() {
      return `${this.$route.name} Page`;
    }
  },
  methods: {
    setRTL (direction) {
      if (direction === "rtl") {
        this.$rtl.enableRTL();
      } else {
        this.$rtl.disableRTL();
      }
    },
    toggleNavbar() {
      document.body.classList.toggle('nav-open');
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove('nav-open');
      this.showMenu = false;
    },
    setPageClass() {
      this.pageClass = `${this.$route.name}-page`.toLowerCase();
    }
  },
  beforeDestroy() {
    this.closeMenu();
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },
  mounted() {
    this.setPageClass();
  },
  watch: {
    $route() {
      this.setPageClass();
    }
  }
};
</script>
<style lang="scss">
.navbar.auth-navbar {
  top: 0;
}

$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}

.wrapper-full-page .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.wrapper-full-page .zoomOut {
  animation-name: zoomOut8;
}
</style>
