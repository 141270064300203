import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import colorUnification from './colorUnification'
import dev from './dev'
import gr from './gr'
import media from './media'
import pl from './pl'
import pub from './pub'
import logs from './logs'
import stat from './stat'
import streaming from './streaming'
import upd from './upd'
import tag from './tag'
import profile from './profile'
import recycle from './recycle'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    error: null
  },
  mutations: {
    setError(state, error) {
      state.error = error
    },
    clearError(state) {
      state.error = null
    }
  },
  actions: {
    
  },
  getters: {
    error: s => s.error
  },
  modules: {
    auth, colorUnification, dev, gr, logs, media, pl, pub, stat, streaming, upd, tag, profile, recycle
  }
})
