import axios from 'axios'
import { responseCheck, errorAnswer } from "@/util/functions.js"

export default {
  actions: {
    //Send played content to server
    async sendPlayed({dispatch}, {formData, playpointid}) {
      const json = JSON.stringify(formData);
      const key = process.env.VUE_APP_LOGS;
      const response = await axios.post(`${key}/api/v2/iplay/send/list/${playpointid}`, json, {
        headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json'
        }
      }) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Send heartbeat to server
    async sendHeartbeat({dispatch}, data) {
      const json = JSON.stringify(data);
      const key = process.env.VUE_APP_LOGS;
      const response = await axios.post(`${key}/api/v2/heartbeat/send`, json, {
        headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json'
        }
      }) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    }
  }
}