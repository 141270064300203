<template>
  <footer class="footer">
    <div class="container-fluid">
      <ul class="nav">
        <li class="nav-item">
          <a
            href="http://melofone.media"
            target="_blank"
            rel="noopener"
            class="nav-link"
          >
            Melofone Team
          </a>
        </li>
        <li class="nav-item">
          <a
            href="http://melofone.media"
            target="_blank"
            rel="noopener"
            class="nav-link"
          >
            {{ $t('footer.about') }}
          </a>
        </li>
      </ul>
      <div class="copyright">
        &copy; {{ year }}, 
        <a
          href="http://melofone.media"
          target="_blank"
          rel="noopener"
          >Melofone Team </a>
        {{ $t('footer.foot1') }} <i class="tim-icons icon-heart-2"></i> {{ $t('footer.foot2') }}
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
