<template>
  <div id="#app">
   <router-view></router-view>
  </div>
</template>

<script>

export default {
  methods: {
    initializeLayout() {
      if (!this.$rtl.isRTL) {
        // Just make sure rtl css is off when we are not on rtl
        this.$rtl.disableRTL();
      }
    }
  },
  mounted() {
    this.initializeLayout();
  }  
};
</script>
