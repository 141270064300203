import axios from 'axios'
import { responseCheck, errorAnswer } from "@/util/functions.js"

export default {
  actions: {
    // User login function
    async auth_login({dispatch}, formData) {
      const json = JSON.stringify(formData);
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.post(`${key}/users/authenticate`, json, {
        headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json'
        }
      }) 
      .then(response => {
        response = response.data;
        localStorage.setItem('user', JSON.stringify(response));
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.token;
      })
      .catch(error => errorAnswer(error));
    },

    // User logout function
    async auth_logout({commit}) {
      localStorage.removeItem('user');
      delete axios.defaults.headers.common['Authorization'];
    },

    // User lock function
    async auth_lock({commit}) {
      let updateLS = JSON.parse(localStorage.getItem('user'));
      updateLS.token = '';
      localStorage.setItem('user', JSON.stringify(updateLS));
      delete axios.defaults.headers.common['Authorization'];
    },

    // Personalization user data read
    async personRead({dispatch}, uid) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/profile/read/${uid}`) 
        .then(response => {
          responseCheck(response);
          response = response.data;
          localStorage.setItem('personalization', JSON.stringify(response));
        })
        .catch(error => errorAnswer(error));
      return response;
    },

    // Personalization user data save
    async personSave({dispatch}, data) {
      const json = JSON.stringify(data);
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.post(`${key}/api/v2/profile/save`, json, {
        headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json'
        }
      }) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    }
  }
}
