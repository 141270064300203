import axios from 'axios'
import { responseCheck, errorAnswer } from "@/util/functions.js"

export default {
  actions: {
    //List of all devices for start Logs page
    async fetchUpdDev({dispatch}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/update/devices`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    // List of all updates files
    async fetchUpdFiles({dispatch}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/update/files`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    // List of all updates chanels
    async fetchUpdChanels({dispatch}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/update/channel`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    // Edit Chanels status /api/v2/update/deploypackage
    async editUpdChanels({dispatch}, uid) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/update/channel/${uid}/staterotate`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    // List of all Deploy packages
    async fetchUpdDepPack({dispatch}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/update/deploypackage`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    // Edit Deploy packages status
    async editUpdDepPack({dispatch}, uid) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/update/deploypackage/${uid}/staterotate`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    // List of all Packages
    async fetchPackages({dispatch}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/update/package`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    // Edit Packages status
    async editPackages({dispatch}, uid) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/update/package/${uid}/staterotate`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    // Packages by ID information
    async fetchPkById({dispatch}, uid) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/update/package/${uid}`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    // List of files in curent Package
    async fetchFilesInPk({dispatch}, uid) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/update/package/${uid}/files`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },

    //Dell file from Package
    async deleteFromPk({dispatch}, {uid, fileuid, linkid}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.get(`${key}/api/v2/update/package/${uid}/excludefile/${fileuid}/link/${linkid}`) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },
    
    //Upload update files to the system
    async uploadUpdates({dispatch, commit}, {uid, files}) {
      const key = process.env.VUE_APP_PROJECT;
      const response = await axios.post(`${key}/api/v2/update/upload/${uid}`, files, {
        headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: function( progressEvent ) {
          commit('setUpload', Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
        }
      }) 
        .then(response => responseCheck(response))
        .catch(error => errorAnswer(error));
      return response;
    },
  }
}