<template>
  <div class="not-found-page">
    <card class="col-md-6 ml-auto mr-auto">
    <div class="centered">
      <h1><i class="not-found-icon tim-icons icon-zoom-split"></i>404</h1>
      <p>{{ $t('notFound.title') }}</p>
    </div>
    </card>
  </div>
</template>

<script>

export default {
  
};
</script>
<style lang="scss">
.not-found-page {
  .full-page > .content,
  .centered {
    min-height: calc(50vh - 60px);
  }
  .not-found-icon {
    margin-right: 50px;
  }
  .centered {
    h1,
    i {
      font-size: 50px;
      text-align: center 
    }
    p {
      font-size: 20px;
      text-align: center 
    }
    display: flex;
    padding-bottom: 50px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
  }
}
</style>
